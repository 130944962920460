<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="下单日期" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="selDaterangeWidth"
            :clearable="false"
            :picker-options="selpickerOptions"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="标准名称" prop="goods_name">
          <el-input
            size="mini"
            v-model.trim="table.params.goods_name"
            placeholder="请输入"
            clearable
            @keydown.enter.native="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="供应商/采购员" prop="supplier_id">
          <el-select v-model="table.params.supplier_id" filterable id="bind_id" clearable placeholder="请输入" @focus="getSupplierSel" @change="onSearch">
            <el-option v-for="item in supplierArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户名称" prop="merchant_id">
          <el-select v-model="table.params.merchant_id" filterable placeholder="请选择" clearable @focus="getMerchantSel" @change="changeMerchant">
            <el-option
              v-for="item in merchantArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="食堂名称" prop="address_id">
          <el-select v-model="table.params.address_id" filterable placeholder="请输入" clearable @focus="getStoreSel" @change="onSearch">
            <el-option v-for="item in storeArr" :key="item.id" :label="item.name" :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">({{ item.merchant_name }})</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="计划交货日期" prop="delivery_start">
          <el-date-picker
            v-model="table.params.delivery_start"
            type="date"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="onSearch">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd" style="display:flex;align-items: center;">
        <el-button :size="subUnitSize" type="primary" plain @click="exportData('/admin/purchase_order/exportGoods')" icon="el-icon-download" >导出</el-button>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      :showIndex="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:spec="{row}">
        <span v-if="row.spec_ids.length > 1">存在多个商品规格</span>
        <span v-else>{{row.spec[0]}}</span>
      </template>
      <template v-slot:cate_name="{row}">
        <span>{{row.cate_name}}</span>
        <span v-if="!!row.order_name_tow"> / {{row.order_name_tow}}</span>
      </template>
      <template v-slot:delivery_money="{row}">
        <span v-if="row.delivery_money == 0">{{row.delivery_money}}</span>
        <span v-else>{{row.delivery_money}}元</span>
      </template>
      <template v-slot:remark="{row}">
        <template v-if="row.remark.length > 0">
          <div v-for="(v,i) in row.remark" :key="i">
            <span>{{v}}</span>
            <span v-if="i < row.remark.length -1">，</span>
          </div>
        </template>
        <span v-else></span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showDetail(row)">查看详情</el-button>
      </template>
    </VTable>

    <!-- 明细详情 -->
    <detail ref="detail" :sleDate="table.params" @refresh="getTable"></detail>

  </div>
</template>

<script>
import Detail from './components/Detail.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'

export default {
  name: 'SupplierIndex',
  components: {
    Detail,
    VTable,
  },
  mixins: [mixinTable],
  computed:{
    ...mapGetters(['areaArr'])
  },
  data() {
    return {
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "good_name", label: "标准名称", hidden: false },
        { name: "cate_name", label: "下单分类", width:'120', showTooltip:true, hidden: false},
        { name: "unit_name", label: "单位", width:'90', hidden: false},
        { name: "spec", label: "规格",  hidden: false},
        { name: "purchase_count", label: "采购单笔数",  hidden: false},
        { name: "order_num", label: "订单数量",  hidden: false},
        { name: "sys_num", label: "自采数量",  hidden: false},
        { name: "total_order_num", label: "总下单数量",  hidden: false},
        { name: "use_inventory", label: "使用库存",  hidden: false},
        { name: "loss_num", label: "计算损耗数",  hidden: false},
        { name: "purchase_num", label: "总采购数量",  hidden: false},
        { name: "remark", label: "备注", width:'150',hidden: false},
        { name: "bind_name", label: "供应商/采购员",width:'120', showTooltip: true, hidden: false},
        { name: "delivery_num", label: "收货数量",  hidden: false},
        { name: "delivery_money", label: "收货金额",  hidden: false},
        { name: "change_time", label: "更新时间", width:'150', hidden: false},
        { name: "action", label: "操作",width: "120", fixed:"right", hidden: false }
      ],
      loading: false,
      table: {
        loading: false,
        params: {
          date: [],
          start: '',
          end: '',
          delivery_start: '',// 计划交货日期
          delivery_end: '',
          goods_name: '',
          supplier_id: '',
          address_id: '',
          order_cate_arr: [],
          order_cate_id: '', // 下单分类
          order_cate_tow: '',// 二级下单分类
          status: 2,  // 2:已确认
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      multipleSelection: [],
      supplierArr: [], // 供应商/采购员
      merchantArr: [], // 客户列表
      storeArr:[], // 食堂
      orderCateArr: [], // 下单分类
      selpickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.isRestart = false
          this.pickerMinDate = minDate.getTime()
          if (maxDate) {
            this.pickerMinDate = ''
          }
        },
        disabledDate: (time) => { //查询时间跨度为7天
          if (this.curveTime && this.isRestart) {
            return // 存在选中的日期且没有重新选择日期 不做禁用处理
          }
          if (this.pickerMinDate !== '') {
            const one = 7 * 24 * 3600 * 1000
            const minTime = this.pickerMinDate - one
            const maxTime = this.pickerMinDate + one
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      },
    }
  },
  created() {
    // 默认当天
    const end = new Date();
    const start = new Date();
    // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30); // 往前推一个月
    this.table.params.date[0] = this.$moment(start).format("YYYY-MM-DD");
    this.table.params.date[1] = this.$moment(end).format("YYYY-MM-DD");

    this.getTable();
  },
  methods: {
    getTable() {
      let _params = {...this.table.params}
      let _date = this.table.params.date;
      if(!!_date && _date.length > 0) {
        _params.start = !!_date ? _date[0] : '';
        _params.end = !!_date ? _date[1] : '';
      } 
      _params.delivery_start = !!_params.delivery_start ? _params.delivery_start : '';// 计划交货日期
      _params.delivery_end = !!_params.delivery_start ? _params.delivery_start : '';
    
      delete _params.date;
      delete _params.order_cate_arr;
      this.table.loading = true;
      this.$http.get('/admin/purchase_order/goods', {params:_params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.table.params.order_cate_arr = [];
      this.table.params.order_cate_id = '';
      this.table.params.order_cate_tow = '';
      this.getTable();
    },
    // 客户的食堂列表
    getStore() {
      this.$http.get('/admin/path/storeList', {params: {page:1,count:10000}}).then(res => {
        if(res.code === 1) {
          this.storeArr = res.data.list;
        }
      })
    },
    // 下单明细
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 全部导出
    exportData(url) {
      let str = ""
      let _params = {...this.table.params}
      let _date = this.table.params.date;
      if(_date &&  _date.length > 0) {
        _params.start = _date[0]
        _params.end = _date[1]
      } else {
        _params.start = '';
        _params.end = '';
      }
      delete _params.date;
      delete _params.order_cate_arr;
      _params.delivery_start = !!_params.delivery_start ? _params.delivery_start : '';// 计划交货日期
      _params.delivery_end = !!_params.delivery_start ? _params.delivery_start : '';

      // 没有勾选数据默认是全部导出，勾选了数据就是批量导出
      if(this.multipleSelection.length > 0) {
        let strarr = this.multipleSelection.map(v => {
          return {
            good_name: v.good_name,
            bind_id: v.bind_id,
            unit_name: v.unit_name,
            uni_spec: v.uni_spec,
          }
        })
        // 批量导出需传ids
        _params.ids = JSON.stringify(strarr)
      }
      let obj = {
        ..._params
      }
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
  }
}
</script>
<style scoped>
.remarkText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
