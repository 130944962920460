var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],staticClass:"addPerson__wrap"},[_c('el-header',{staticClass:"shadow page-header__wrap"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"backBtn",on:{"click":_vm.handleReturn}},[_c('el-image',{staticClass:"back-img",attrs:{"src":require('@/assets/img/back.png')}}),_c('span',[_vm._v("返回")])],1),_c('div',{staticClass:"title"},[_c('span',{staticClass:"site"},[_vm._v("当前位置：")]),_vm._v(" "+_vm._s(_vm.$route.meta.title)+" "),_c('span',[_vm._v(" > ")]),_vm._v(" 下单明细 ")])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"mainBox"},[_c('div',{staticClass:"itemTime"},[_vm._v("订单明细")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.table.data,"row-key":function (row) { return row.id; },"border":""}},[_c('el-table-column',{attrs:{"type":"index","label":"序号","width":"60"}}),_c('el-table-column',{attrs:{"property":"norm_name","label":"标准名称","width":"150"}}),_c('el-table-column',{attrs:{"property":"store_name","label":"数据来源","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(!!row.store_name)?_c('span',[_vm._v(_vm._s(row.store_name))]):_c('span',[_vm._v(_vm._s(row.source == 1 ? '销售订单' : '平台自采'))])]}}])}),_c('el-table-column',{attrs:{"property":"purchase_no","label":"采购单号","width":"190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.showDetail(row)}}},[_vm._v(_vm._s(row.purchase_no))])]}}])}),_c('el-table-column',{attrs:{"property":"num","label":"下单数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.num)+_vm._s(_vm.currRow.unit_name))])]}}])}),_c('el-table-column',{attrs:{"property":"use_inventory","label":"使用库存"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.use_inventory))]),(row.use_inventory > 0)?_c('span',[_vm._v(_vm._s(row.unit_name))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"property":"use_inventory","label":"计算损耗数量"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.loss_num))])]}}])}),_c('el-table-column',{attrs:{"property":"purchase_num","label":"需采购数量","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.purchase_num)+_vm._s(row.unit_name))])]}}])}),_c('el-table-column',{attrs:{"property":"create_time","label":"创建时间","width":"140"}}),_c('el-table-column',{attrs:{"property":"remark","label":"备注","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _vm._l((row.remark),function(v,i){return _c('div',{key:i},[_vm._v(_vm._s(v)+"。")])})}}])})],1),(_vm.table.total > 10)?_c('div',{staticClass:"pageWrap"},[_c('el-pagination',{attrs:{"current-page":_vm.table.params.page,"page-sizes":[10, 20, 50, 100],"page-size":_vm.table.params.count,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.table.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1):_vm._e()],1)])],1),_c('PurchaseDetail',{ref:"purchasedetail"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }